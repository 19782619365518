<script type="text/javascript">
    // 定义一些公共的属性和方法
    const http = 'http://admin.jianchenghr.com'
    function commonFun() {
    
    }
    // 暴露出这些属性和方法
    export default {
        http,
        commonFun
    }
</script>