<template>
  <div class="log_wrapper" v-if="show">
    <div class="logo auto"><img src="/images/logo.png" alt=""></div>


<div class="tx1">用户登录</div>



  <div  class="field_cell_group">
   <div  class="field_cell">
    <label ><input  type="text" v-model="user" placeholder="请输入手机号" autocomplete="off" class="log_field" /></label>
   </div> 
   <div  class="field_cell">
    <label ><input  placeholder="请输入密码" v-model="psw" autocomplete="off" type="password" class="log_field" /></label> 

   </div> 
   <!----> 
   <!---->
  </div> 

<van-button class='dl' type="primary" @click='tj' round size="large" color='#1989fa'>登录</van-button>

<div class="tip">没有账号？
  <router-link :to="'/reg'">立即注册
 </router-link>
 </div>

<foot></foot>

  </div>
</template>

<script>
import Vue from 'vue';
import Foot from './common/Foot.vue';
import { Button } from 'vant';
import global from './common.vue';
import { Toast } from 'vant';
Vue.use(Toast);
Vue.use(Button);

export default {
  name: 'Home',
     components:{
   Foot
    },data(){
          return {
user:'',
psw:'',
show:0

          }
    },
    created(){
 document.title = '用户登录-鉴承教育';

 if(localStorage.getItem('uid')!=null && !localStorage.getItem('user')!=null){
  this.$router.push('/index');
}else{
this.show=1;
}
    },methods: {
    tj:function(){
       var that=this;
  var phoneReg = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/;
   if(that.user==''){
     Toast('请输入手机号');
     return false;
      }
    if(!phoneReg.test(that.user)){
           Toast('请正确输入手机号');
      }




        this.$axios.post(global.http+'/api/log.php',{
        params:{
         user:that.user,
         psw:that.psw,
      
        }
        }).then((res) => {

if(res.data.code==0){
 Toast(res.data.msg);
}else{

if(res.data.code==1){

  localStorage.setItem('user',that.user);

 Toast({
    message: res.data.msg,
   forbidClick: true,
   onClose:function(){
localStorage.setItem('user',that.user);
localStorage.setItem('uid',res.data.uid);
localStorage.setItem('type',res.data.type);

  that.$router.push('/index')
   }
 }
 );

}else{


 Toast({
    message: res.data.msg,
   forbidClick: true,
   onClose:function(){

   }
 }
 );

}


}

    })








    }
    },

}



</script>


<style lang="less"  scoped>
.log_wrapper{
   max-width: 5.8rem;
    margin: 0 auto;
    padding:0rem .3rem;overflow: hidden;
        display: flex;
min-height: 100vh;
flex-direction: column;
}

.head_reg_txt{font-size: 0.15rem;}

.tx1{
    padding-top: .4rem;
text-align: center;
    font-size: .24rem;
    color: #333;margin-bottom: 0.2rem;
}
.logo{width:2.4rem;padding-top: 0.5rem;
img{width: 100%;}
}

.field_cell{
    width: 100%;
    border-bottom: .01rem solid #eee;
    position: relative;
    padding-top: .133333rem;
}

.log_field{
    border: 0;
    width: 100%;
    display: block;
    color: #333;
    font-size: .16rem;
    padding: .12rem 0;
}
.dl{margin-top: 0.3rem;}
.tip{
  text-align: center;
  margin-top: 0.15rem;
  color:#a6a6a7;
a{color:#444;}

}
</style>