<template>
<div class="foot">上海鉴承教育科技有限公司 &nbsp;  沪ICP备2021003325号-2</div>

</template>

<script>

export default {
  name: 'Foot',

}



</script>


<style lang="less">

</style>